import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
// import { Event } from '../models/event.model';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  url: any = environment.API_URL;


  constructor(
    private http: HttpClient
  ) { }
  
  getFaqsList(data: any) {
    return this.http.get<any>(this.url + 'faq/lists?pageNo=' + data.pageNo + '&limit=' + data.limit +  '&type=' + data.type+  '&languageId=' + data.languageId);
  }

  getFaqById(data: any) {
    return this.http.get<any>(this.url + 'faq/lists?id=' + data.id + '&type=' + data.type+  '&languageId=' + data.languageId);
  }

  addFaq(data: any) {
    return this.http.post(this.url + 'faq/create', data);
  }

  editFaq(data: any) {
    return this.http.patch(this.url + 'faq/update', data);
  }

  deleteFaq(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({}), body: data
  };
    return this.http.delete(this.url + 'faq/delete', httpOptions);
  }
}
