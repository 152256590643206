import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ConfirmPasswordValidator {
	/**
	 * Check matching password with confirm password
	 * @param control AbstractControl
	 */
	static MatchPassword(control: AbstractControl): ValidationErrors | null {
		const newPassword = control.get('newPassword')!.value;

		const confirmPassword = control.get('confirmPassword')!.value;

		if (newPassword !== confirmPassword) {
			control.get('confirmPassword')!.setErrors({ConfirmPassword: true});
		} else {
			return null;
		}
        return null
	}
}
