import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { Chart } from 'node_modules/chart.js/dist/chart.js';
import { DashboardService } from './../services/dashboard.service';
import { FeedbackService } from '../../views/feedback/feedback.service'
import { CompanyService } from 'src/app/views/companies/services/company.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public canvas: any;
  public ctx: any;
  public chart: any;
  dashboardData: any = {};
  graphData: any;
  months: number = 6
  public labels: any = [
    'Com1',
    'Com2',
    'Com3',
    'Com4',
    'Com5',
    'Com6',
    'Com7'
  ];
  public dataCases: any = {
    chart1: [1000, 5000, 15000, 25000, 10000, 20000, 5000, 2000, 7000, 30000],
  };


  constructor(private _header: HeaderService, private dashboardService: DashboardService, private feedBackService: FeedbackService,
    private companyService: CompanyService) { }

  ngOnInit(): void {
    this._header.welcomeUserMsg.next(true);
    this.getDashBordData('Manager')
    // this.getFeedback()
  }

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

  feedbacks = [
    {
      title: 1,
    },
    {
      title: 1,
    },
    {
      title: 1,
    },
  ];

  CHART_COLORS = {
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)'
  };

  // Chart
  private createLineChart(labels, dataCases, chartId) {
    this.canvas = document.getElementById(chartId);
    this.ctx = this.canvas.getContext('2d');

    this.chart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Count',
            data: dataCases.chart1,
            backgroundColor: [
              this.CHART_COLORS.red,
              this.CHART_COLORS.orange,
              this.CHART_COLORS.yellow,
              this.CHART_COLORS.green,
              this.CHART_COLORS.blue,
              this.CHART_COLORS.purple,
              this.CHART_COLORS.grey,
            ],
            borderColor: [
              this.CHART_COLORS.red,
              this.CHART_COLORS.orange,
              this.CHART_COLORS.yellow,
              this.CHART_COLORS.green,
              this.CHART_COLORS.blue,
              this.CHART_COLORS.purple,
              this.CHART_COLORS.grey,
            ],
            fill: false,
            borderWidth: 2,
            tension: 0.3,
          },
        ],
      },
      options: {
        plugins: {
          legend: false // Hide legend
      },
        scales: {
          x: {
            grid: {
              display: false,
            },
          }
        },
        title: {
          display: false,
          text: 'First chart',
        },
        tooltips: {
          mode: 'index',
          intersect: true,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
      },
    });

  }

  clearCanvas(context, canvas) {
    this.chart.destroy();
  }

  type: any;
  selectType(type) {
    this.clearCanvas(this.ctx, this.canvas)
    this.type = type
    this.getDashBordData(type)
  }
  getDashBordData(type: any) {
    // this.dashboardService.getUserList({
    //   "limit": 100,'pageNo': 1
    // }).subscribe((res: any) => {
    //   this.companyService.getCompanyList({
    //     "pageNo": 1, "limit": 100
    //   }).subscribe((res1: any) => {
    //     this.dashboardData.companyCount = res1.data.totalData;
    //     this.dashboardData.usersCount = res.data.totalData;
    //     this.createLineChart(this.labels, this.dataCases, 'myChart');
    //   })
    // })
    this.dashboardService.getDashboardData().subscribe((res: any) => {
      this.dashboardData = res.data;
      this.graphData = res.data.communityList.filter((el: any) => el.type === type);
      this.labels = this.graphData.map((el: any) =>  el.name);
      this.dataCases = {
        chart1: this.graphData.map((el: any) => el.totalMembers)
      }
      console.log(this.labels)
      console.log(this.dataCases)
      // this.dataCases = {
      //   chart1: Object.values(this.graphData),
      // };

      this.createLineChart(this.labels, this.dataCases, 'myChart');
    })
  }
  getFeedback() {
    this.feedBackService.getFeedbackLists(
      { "fId": "", "pageLimit": '5', "page": '1', "search": "" }
    ).subscribe((res: any) => {
      this.feedbacks = res.result;

    })
  }
}
