import { Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/shared/services/header.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../auth/services/auth.service';
import { ConfirmPasswordValidator } from './confirm-password.validator';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit, OnDestroy {
  hide = true;
  hide1 = true;
  hide2 = true;
// Connection Service status 
status = 'ONLINE';
isConnected = true;


  message: string;
  imagePath: any;
  imgURL: any;
  uploaded: boolean = false;
  fileName: any;
  currentUser: any;
  passChangeMsg: boolean = false;
  modalRef: BsModalRef;

  submitted: boolean = false;
  changed: boolean = false;


  imgChangeEvt: any = '';
  cropImgPreview: any = '';
  singalurl: any;
  selectedFile: any;
  @ViewChild('template2') template2: TemplateRef<any>;
  @ViewChild('myFile2') myFile2: ElementRef;

  @HostListener("click")
  clicked() {
    this.myFile2.nativeElement.value = '';
    // this.eventForm.controls['myFile'].setValue('');
  }


  profileForm = new FormGroup({
    myFile: new FormControl('', Validators.required),
    fname: new FormControl('', Validators.required),
    lname: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  get f() {
    return this.profileForm.controls;
  }

  
  passwordForm:any;
  constructor(
    private _header: HeaderService,
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private fb: FormBuilder,    
  ) {   
    
   }


  lang: string = 'eng';
  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    this.profileForm.reset();
    this._header.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;
    this.imgURL = this.currentUser.profileImg ? this.currentUser.profileImg : '../../../../assets/images/edit-user-img.svg';

    this.profileForm.controls['myFile'].setValue(this.currentUser.profileImg, {
      onlySelf: true,
    });
    this.profileForm.controls['fname'].setValue(this.currentUser.fname, {
      onlySelf: true,
    });
    this.profileForm.controls['lname'].setValue(this.currentUser.lname, {
      onlySelf: true,
    });
    this.profileForm.controls['email'].setValue(this.currentUser.email, {
      onlySelf: true,
    });
    // Changed Password Init Form
    this.initForm();
  }

  // Change Password Init Password init()
  initForm() {
    this.passwordForm = this.fb.group({
      oldPassword: new FormControl('', [Validators.required,Validators.minLength(6)]),
      newPassword: new FormControl('', [Validators.required,Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required])
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    })
  }

  get p() {
    return this.passwordForm.controls;
  }
  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

  // confirmation modal box
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  onFileChange(event: any): void {
    this.imgChangeEvt = event;
    this.openModal(this.template2);

  }
  cropImg(e: ImageCroppedEvent) {
    //this.cropImgPreview = e.base64;
    this.imgURL = e.base64
    this.singalurl = e.base64
  }


  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }


  uploadImage() {

    if (this.singalurl) {

      var block = this.singalurl.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];

      // Convert it to a blob to upload
      var blob = this.base64toBlob(realData, contentType);
      let d = new Date()
      let dt = d.getTime()
      this.selectedFile = blob;
      this.selectedFile.name = "skill-ontario-" + dt + ".jpg"
      this.selectedFile.lastModified = dt
      this.selectedFile.mimetype = 'image/jpg'
      let fd = new FormData();
      fd.append('myFile', this.selectedFile);

      this.sharedService.uploadImage(fd)
        .subscribe((res: any) => {
          this.imgURL = res.result.imgPath;
          this.fileName = res.result.imgPath
          this.modalRef.hide();
          this.singalurl = '';
          this.profileForm.controls['myFile'].setValue(this.imgURL);
          this.myFile2.nativeElement.value = '';

        },
          err => {
            // console.log('Error on file upload', err);
          }
        )

      //console.log(blob)
    } else {
      // console.log("Your browser does not support File API");
    }

  }

  editProfile() {
    this.submitted = true;
    if (this.profileForm.invalid) return;
    this.submitted = false;
    let data = {
      profileImg: this.fileName,
      userId: this.currentUser._id,
      fname: this.profileForm.value.fname,
      lname: this.profileForm.value.lname,
      email: this.profileForm.value.email,
    };
    this.authService.updateuser(data).subscribe((res: any) => {
      localStorage.setItem('currentUser', JSON.stringify(res.result));
      this.router.navigateByUrl('/dashboard');
    });
  }



  changePassword() {
    this.changed = true;
    if (this.passwordForm.invalid || this.passwordForm.pristine) return;
    this.changed = false;
    let data = {
      oldPassword: this.passwordForm.value.oldPassword,
      password: this.passwordForm.value.newPassword,
      confirmPassword: this.passwordForm.value.confirmPassword,
    };

    this.authService.changePassword(data).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.passwordForm.controls['oldPassword'].reset();
        this.passwordForm.controls['newPassword'].reset();
        this.passwordForm.controls['confirmPassword'].reset();
        // this.passChangeMsg = true;
        this.toastr.success('Password changed Successfully!', res.message);
      } else {
        console.log('changePassword ran failed', res)
      }
    },
      (err) => {
        console.log('changePassword ran failed', err)
        this.toastr.error(err.error.message);
      });
  }
}


