import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
// import { Event } from '../models/event.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  url: any = environment.API_URL;


  constructor(
    private http: HttpClient
  ) { }

  getCompanyList(data: any) {
    return this.http.get(this.url + 'AdminRoute/getComapnyList?limit='+ data.limit + '&pageNo='+ data.pageNo);
  }

  getCompanyDataById(data: any) {
    return this.http.get(this.url + 'AdminRoute/getComapnyDetails?id='+ data.eventId);
  }

  addCompany(data: any) {
    return this.http.post(this.url + 'AdminRoute/addCompany', data);
  }

  editCompany(data: any) {
    return this.http.post(this.url + 'AdminRoute/editCompany', data);
  }

  deleteCompany(data: any) {
    return this.http.post(this.url + 'AdminRoute/deleteCompany', data);
  }

  //old
  getEvents(data: any) {
    return this.http.post(this.url + 'event/list', data);
  }

  deleteEvent(data: any) {
    const httpOptions = {
        headers: new HttpHeaders({}), body: data
    };
    console.log('data', data);
    return this.http.delete(this.url + 'event/delete', httpOptions);
  }
}
