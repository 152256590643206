<div class="header">
  <div class="left-sec">
    <ng-container *ngIf="welcomeUserMsg">
      <h1>RehvUp</h1>
      <p class="mt-2">Hello {{ userName }}, Welcome Back!</p>
    </ng-container>
    <h1 *ngIf="companiesHeading">Companies</h1>
    <h1 *ngIf="excoachHeading">Engagement Coach</h1>

    <h1 *ngIf="eventsHeading">Events</h1>
    <h1 *ngIf="careersHeading">Career Profile</h1>
    <h1 *ngIf="sectorHeading">Sectors</h1>
    <h1 *ngIf="profileHeading">Job Profile / Trade</h1>
    <h1 *ngIf="newsHeading">News</h1>
    <h1 *ngIf="resourcesHeading">Resources</h1>
    <h1 *ngIf="educationHeading">Education Values</h1>
    <h1 *ngIf="financialHeading">Opportunities</h1>
    <h1 *ngIf="quizHeading">Quiz</h1>
    <h1 *ngIf="usersHeading">Users</h1>
    <h1 *ngIf="communitiesHeading">Communities of Interest</h1>
    <h1 *ngIf="managerCommunitiesHeading">Manager Communities</h1>
    <h1 *ngIf="feedbackHeading">Feedback</h1>
    <h1 *ngIf="partnerHeading">Partners</h1>
    <h1 *ngIf="notificationHeading">Notifications</h1>
    <h1 *ngIf="faqsHeading">FAQs</h1>
  </div>

  <!-- <div class="middle-sec">
        <div class="form-group" *ngIf="router.url != '/dashboard'">
            <span> <img src="../../../assets/icons/search-icon.svg" alt=""> </span>
            <input class="form-control" type="text" placeholder="Search" aria-label="Search">
        </div>
    </div> -->

  <div class="right-sec">
    <div class="btn-group lang" dropdown placement="bottom right">
      <button
        id="button-alignment"
        dropdownToggle
        type="button"
        class="btn dropdown-toggle"
        style="min-width: 170px; margin-right: 15px"
        aria-controls="dropdown-alignment"
      >
        <img
          class="user-lang"
          src="../../../assets/icons/english-flag.svg"
          alt=""
        />
        {{ lang }}
        <img
          class="drop-down-arrow"
          src="../../../assets/icons/drop-down-arrow.svg"
          alt=""
        />
      </button>
      <ul
        id="dropdown-alignment"
        style="margin-right: 15px"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="button-alignment"
      >
        <li role="menuitem">
          <a
            class="dropdown-item"
            [ngClass]="default ? 'language' : ''"
            aria-selected="default ? true : false"
            (click)="englishSelection()"
          >
            <img
              class="user"
              src="../../../assets/icons/english-flag.svg"
              alt=""
            />
            English</a
          >
        </li>
        <li role="menuitem">
          <a
            class="dropdown-item"
            (click)="frenchSelection()"
            aria-selected="true"
            [ngClass]="!default ? 'language' : ''"
            ><img
              class="user"
              src="../../../assets/icons/english-flag.svg"
              alt=""
            />
            Français</a
          >
        </li>
      </ul>
    </div>

    <div class="btn-group" dropdown placement="bottom right">
      <button
        id="button-alignment"
        dropdownToggle
        type="button"
        class="btn dropdown-toggle"
        aria-controls="dropdown-alignment"
      >
        <img class="user" [src]="sanitizeImage(profilePic)" alt="" /> {{ userName }}
        <img
          class="drop-down-arrow"
          src="../../../assets/icons/drop-down-arrow.svg"
          alt=""
        />
      </button>
      <ul
        id="dropdown-alignment"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="button-alignment"
      >
        <li role="menuitem">
          <a
            class="dropdown-item"
            style="background: #346ef6; color: #fff"
            routerLink="/edit-profile"
            >Change Password</a
          >
        </li>
        <li style="cursor: pointer" role="menuitem">
          <a class="dropdown-item" (click)="openModal(template)">Logout</a>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- Logout fonfirm -->
<ng-template #template>
  <div class="modal-header">
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <img src="../../../../../assets/images/create-successfull-img.svg" alt="" />
    <p>Are you sure you <br />want to logout</p>
    <div class="modal-btn d-flex justify-content-around">
      <button class="btn btn-red" (click)="cancel()" style="color: #000">
        No
      </button>
      <button class="btn btn-blue" (click)="confirm()">Yes</button>
    </div>
  </div>
</ng-template>
