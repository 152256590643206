import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class SharedService {
    url: any = environment.API_URL
    constructor(
        private http: HttpClient
    ) {

    }

    uploadImage(file:any){
        return this.http.post(this.url + 'AdminRoute/uploadImage', file);
    }

    postLanguage(data:any){
    return this.http.post(this.url + 'coach/changeLanguages', data);
    }

    getLanguage(){
        return this.http.get(this.url + 'coach/getLanguage')
    }
}