import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HeaderService } from '../services/header.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  companiesHeading: boolean = false;
  excoachHeading: boolean = false;
  faqsHeading: boolean = false;
  
  welcomeUserMsg: boolean = false;
  eventsHeading: boolean = false;
  careersHeading: boolean = false;
  sectorHeading: boolean = false;
  profileHeading: boolean = false;
  resourcesHeading: boolean = false;
  newsHeading: boolean = false;
  educationHeading: boolean = false;
  financialHeading: boolean = false;
  quizHeading: boolean = false;
  usersHeading: boolean = false;
  communitiesHeading : boolean = false;
  managerCommunitiesHeading : boolean = false;
  feedbackHeading: boolean = false;
  partnerHeading: boolean = false;
  notificationHeading: boolean = false;
  default: boolean;
  lang: string;

  private returnUrl: any;

  profilePic: String = '';
  modalRef: any;

  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    public domSanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this._header.companyHeading.subscribe((res) => {
      this.companiesHeading = res;
    });
    this._header.excoachHeading.subscribe((res) => {
      this.excoachHeading = res;
    });
    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });
    this._header.eventHeading.subscribe((res) => {
      this.eventsHeading = res;
    });
    this._header.careersHeading.subscribe((res) => {
      this.careersHeading = res;
    });
    this._header.sectorHeading.subscribe((res) => {
      this.sectorHeading = res;
    });
    this._header.profileHeading.subscribe((res) => {
      this.profileHeading = res;
    });
    this._header.resourcesHeading.subscribe((res) => {
      this.resourcesHeading = res;
    });
    this._header.newsHeading.subscribe((res) => {
      this.newsHeading = res;
    });
    this._header.educationHeading.subscribe((res) => {
      this.educationHeading = res;
    });
    this._header.financialHeading.subscribe((res) => {
      this.financialHeading = res;
    });
    this._header.quizHeading.subscribe((res) => {
      this.quizHeading = res;
    });
    this._header.usersHeading.subscribe((res) => {
      this.usersHeading = res;
    });
    this._header.communitiesHeading.subscribe((res) => {
      this.communitiesHeading = res;
    });
    this._header.managerCommunitiesHeading.subscribe((res) => {
      this.managerCommunitiesHeading = res;
    });
    this._header.feedbackHeading.subscribe((res) => {
      this.feedbackHeading = res;
    });
    this._header.partnerHeading.subscribe((res) => {
      this.partnerHeading = res;
    });
    this._header.notificationHeading.subscribe((res) => {
      this.notificationHeading = res;
    });
    this._header.faqsHeading.subscribe((res) => {
      this.faqsHeading = res;
    });

    
  }

  userName: String;

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    let language = localStorage.getItem('lang');
    this.default = language ? (language == 'en' ? true : false) : true;
    // this.lang = language ? (language == 'eng' ? 'English' : 'Français') : 'English';
    this.userName = user.name;
    this.profilePic = user.profilePic ? user.profilePic : '../../../assets/icons/user-img.png';
    this.getLanguage();
    // redirect back to the returnUrl before login
    // this.route.queryParams.subscribe(params => {
    //   console.log('params', params);
    //   this.returnUrl = params.returnUrl || '/';
    // });
  }

  languages: any;
  getLanguage() {
    this.sharedService.getLanguage().subscribe((res: any) => {
      console.log('language response', res.data);
      if (res.statusCode == 200) {
        this.languages = res.data;
        let lang: any = localStorage.getItem('lang');
        this.lang = lang ? (lang == 'en' ? 'English' : 'Français') : 'English';
        if (lang) {
          this.lang = lang == 'en' ? 'English' : 'Français';
        } else {
          this.languages.forEach((el: any) => {
            if (el.lang == "English") {
              localStorage.setItem('lang', 'en');
              localStorage.setItem('language', JSON.stringify(el));
            }
          })
        }

      }
    })
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('lang');
    this.modalRef.hide();
    this.router.navigateByUrl('/auth/login')
  }

  // modal lougout confirmation
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }

  englishSelection() {
    this.default = true;
    this.lang = 'English';
    this.postLanguage();
    // window.location.reload();
    // console.log('this.returnUrl', this.returnUrl);
    // this.router.navigateByUrl(this.returnUrl); // Main page
  }

  frenchSelection() {
    this.default = false;
    this.lang = 'Français';
    this.postLanguage();
    // window.location.reload();
    // console.log('this.rturnUrl', this.returnUrl);
    // this.router.navigateByUrl(this.returnUrl); // Main page
  }

  postLanguage(){
    let language: any;
    if(this.lang === 'English'){
      language = this.languages.find((el: any) => el.lang === 'English');
    } else {
      language = this.languages.find((el: any) => el.lang === 'French');
    }
    this.sharedService.postLanguage({languageId: language._id})
    .subscribe((res:any)=>{
      console.log('post language response', res);
      if(this.lang === 'English'){
        localStorage.setItem('lang', 'en');
        localStorage.setItem('language', JSON.stringify(language));
      } else {
        localStorage.setItem('lang', 'fr');
        localStorage.setItem('language', JSON.stringify(language));
      }
      window.location.reload();
    })
    
  }

  sanitizeImage(image: any) {
    return this.domSanitizer.bypassSecurityTrustUrl(image);
  }
  
}
