import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { AuthService } from '../auth/services/auth.service';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { tap } from 'rxjs/operators';



@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authService.currentUserValue;
        let lang = localStorage.getItem('lang');
        if (currentUser) {
            request = request.clone({
                setHeaders: {
                    authorization: "Bearer " + currentUser.accessToken,
                    // 'x-access-token': currentUser.token,
                    // 'lang': lang ? lang : ''
                }
            })
        } else {
            request = request.clone({
                setHeaders: {
                    // Authorization: "Basic " + window.btoa("3xaUser!@3#:9raPass@3!)#@done")
                }
            })
        }


        return next.handle(request).pipe(
            tap(
                event => {
                    if (event instanceof HttpResponse) {

                        if (event.body.status == 401) {

                            this.authService.logout();
                            this.router.navigate(['/auth/login'])
                            alert('You are already logged in with different device.');
                        }
                    }
                },
                error => {
                    console.log('error', error);
                    if (error.status == 500 || error.status == 401) {
                        this.authService.logout();
                        this.router.navigate(['/auth/login'])
                        alert('You are already logged in with different device.');
                    } else if (error.status == 400) {
                        // this.authService.logout();
                        // this.router.navigate(['/auth/login'])
                        // alert('You are already logged in with different device.');

                    } else {
                        this.authService.logout();
                        this.router.navigate(['/auth/login'])
                        // alert('You are already logged in with different device.');
                    }
                }
            )
        );
    }
}