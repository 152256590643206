import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class UserService {

    url:any = environment.API_URL;

    constructor(
        private http : HttpClient
    ){

    }

    getUserList(data:any){
        let urlString = this.url + 'AdminRoute/getUserList?limit='+ data.limit + '&pageNo='+ data.pageNo + '&userType=' + data.userType;
        if(data.role) {
            urlString =  urlString + '&role=' + data.role
        }
        if(data.companyId) {
            urlString = urlString + '&companyId=' + data.companyId
        }
        return this.http.get(urlString);
    }

    searchUserList(data:any){
        return this.http.get(this.url + 'AdminRoute/getUserList?limit='+ data.limit + '&pageNo='+ data.pageNo + 
            '&userType=' + data.userType + '&role=' + data.role + '&companyId=' + data.companyId);
    }

    updateUser(data:any){
        return this.http.patch(this.url + 'user/update', data);
    }

    getUserDataById(data: any) {
        return this.http.get(this.url + 'AdminRoute/getUserDetails?id='+ data.userId);
    }

    deleteUser(data: any) {
        return this.http.post(this.url + 'AdminRoute/deleteUser', data);
    }
}